/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * This mixin must be used with the @components/cancel-update-dialog.vue component
 */

 import store from '@/store'
 import { mapActions } from 'vuex'

export default {
    beforeRouteLeave (to, from, next) {
      const nextRoute = store.state.navigation.cancelSaveNextRoute
      if (!nextRoute && this.isFormChanged) {
        store.commit('navigation/openCancelSaveDialog', to)
        return
      }

      store.commit('navigation/resetCancelSaveNextRoute')
      return next()
    },
    computed: {
      isFormChanged () {
        throw Error('The computed "isFormChanged()" should be defined in the component, typicaly mapped with a form store isChanged state')
      },
    },
    methods: {
      async save () {
        throw Error('The method "save()" should be defined in the component, typicaly mapped with a form store save action')
      },
      async onCancel () {
        const nextRoute = store.state.navigation.cancelSaveNextRoute
        if (nextRoute) {
          store.commit('navigation/closeCancelSaveDialog')
          this.$router.push(nextRoute)
        }
      },
      async onSave () {
        const nextRoute = store.state.navigation.cancelSaveNextRoute
        if (nextRoute) {
          await this.save()
          store.commit('navigation/closeCancelSaveDialog')
          this.$router.push(nextRoute)
        }
      },
      async isValidatedForm (referenceValidationObserver) {
        let isValidatedForm = false
        await referenceValidationObserver
          .validate()
          .then(async (success) => {
            const inputRequired = referenceValidationObserver.errors
            if (!success) {
              const inputsName = ((Object.entries(
                inputRequired,
              ))
                .filter(x => x[1].length > 0)
                .map(x => x[0]))
              const text = this.$t('form.input_uncomplete') + ' : ' + inputsName.join(', ')
              this.sendErrorMessage(text)
              const firstInputWithAnError = this.$el.querySelector('.v-messages.error--text:first-of-type')
              this.$vuetify.goTo(firstInputWithAnError)
            } else {
              isValidatedForm = true
            }
          })
        return isValidatedForm
      },
      ...mapActions('user', ['sendErrorMessage']),
  },
}
