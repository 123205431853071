






















































































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import cancelSaveDialog from '@/mixins/cancelSaveDialog.js'
import { RawLocation } from 'vue-router'
import { Trainee } from '@/api/interfaces/trainee'
import { isReadonly } from '@/composables/UserGrant'

export default defineComponent({
  setup () {
    const readonly = () => isReadonly()
    return {
      readonly,
    }
  },
  name: 'Trainee',
  components: {
    CancelUpdateDialog: () => import('@/components/cancel-update-dialog.vue'),
    MExistingTrainee: () =>
    import('@/components/molecules/m-existing-trainee.vue'),
    PhoneNumber: () => import('@/components/molecules/input/phone-number.vue'),
    SelectArea: () => import('@/components/molecules/select/area.vue'),
  },
  mixins: [cancelSaveDialog],
  data () {
    return {
      listExistingNeedTraining: [],
    }
  },
  computed: {
    // The `mapFields` function takes an array of
    // field names and generates corresponding
    // computed properties with getter and setter
    // functions for accessing the Vuex store.
    ...mapFields('traineeForm', [
      'trainee.civility',
      'trainee.lastName',
      'trainee.phoneNumber',
      'trainee.email',
      'trainee.firstName',
      'trainee.mobilePhone',
      'trainee.area',
      'trainee.lastNameDeliveryImported',
      'trainee.firstNameDeliveryImported',
      'trainee.phoneNumberDeliveryImported',
      'trainee.mobilePhoneDeliveryImported',
      'trainee.emailDeliveryImported',
    ]),
    ...mapState('area', {
      listAreas: 'list',
    }),
    ...mapState('traineeForm', {
      isTraineeSubmited: 'isSubmitted',
      isFormChanged: 'isChanged',
    }),
    idNeedTraining (): string | null {
      return this.$route.params.idNeedTraining || null
    },
    showCardDelivery (): boolean {
      return !!(this.lastNameDeliveryImported ||
        this.firstNameDeliveryImported ||
        this.phoneNumberDeliveryImported ||
        this.mobilePhoneDeliveryImported ||
        this.emailDeliveryImported)
    },
  },
  methods: {
    ...mapActions('traineeForm', {
      save: 'save',
    }),
    ...mapActions('needTrainingForm', {
      saveNeedTraining: 'save',
      resetNeedTraining: 'reset',
    }),
    ...mapActions('traineeList', {
      loadTrainees: 'load',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(this.$refs.validationObserver)
      if (isValidatedForm) {
        let idNeedTraining = this.idNeedTraining as string
        const trainee: Trainee = await this.save()
        if (!idNeedTraining) {
          const trainingType = this.$route.params.trainingType ? `/api/training_types/${this.$route.params.trainingType}` : null
          this.resetNeedTraining({ trainee, trainingType })
          const needTraining = await this.saveNeedTraining()
          idNeedTraining = needTraining.id
        }
        await this.sendSuccessMessage('trainee.form.saved')
        this.nextRoute = {
          name: 'NeedTraining Edit',
          params: { idNeedTraining: idNeedTraining },
        }
        this.$router.push(this.nextRoute as RawLocation)
      }
    },
    async searchTrainee () {
      this.listExistingNeedTraining = []
      if (!this.email) {
        return
      }
      const isEmailValid = await (this.$refs.trainee_email as any).validate()
      if (isEmailValid.valid) {
        const request = {
          trainee: { email: this.email },
        }
        this.listExistingNeedTraining = await this.loadTrainees(request)
      }
    },
  },
})
